import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";

import { Stack, AppBar, Toolbar, Box, Typography } from "@mui/material";

import useOffSetTop from "../../../hooks/useOffSetTop";

import useResponsive from "../../../hooks/useResponsive";

import { HEADER, NAV } from "../../../config";

import { useSettingsContext } from "../../../components/settings";

// ----------------------------------------------------------------------

const appBarStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  pl: "30px",
  pr: "30px",
};

const innerFlexBoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const appBarTextStyle = {
  fontSize: "16px",
  fontWeight: 500,
  color: "rgba(255, 255, 255, 1)",
};

const contactUsBoxStyle = {
  p: { lg: "38px", sm: "20px", md: "30px", xs: "30px" },
  background: "rgba(97, 204, 245, 1)",
  display: "flex",
  alignItems: "center",
  mr: { sm: "15px", md: "0px" },
};

const contactUsTextStyle = {
  fontSize: { lg: "16px", sm: "15px" },
  fontWeight: { lg: 700, sm: 600 },
  color: "rgba(255, 255, 255, 1)",
  width: "100%",
};

const secontAppBarStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  mt: { xs: "51.3px", lg: "0px", sm: "63.5px" },
};

const logoStyle = {
  width: { lg: "182px", sm: "190px", md: "190px", xs: "140px" },
  height: { lg: "100px", sm: "61px", md: "61px", xs: "55px" },
  pl: "10px",
  mb: "13px",
};
//_______________________________________________________________

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

//______________________________________________________

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  const isDesktop = useResponsive("up", "lg");

  const isSmallScreen = useResponsive("up", "sm");

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent={"flex-end"}
      >
        {(isDesktop || isSmallScreen) && (
          <>
            <Box sx={appBarStyle}>
              <Box sx={{ ...innerFlexBoxStyle }}>
                <img src="/assets/icons/watsup.svg" alt="" />
                <Box sx={{ pl: "10px" }}></Box>
                <img src="/assets/icons/instagram.svg" alt="" />
                <Box sx={{ pl: "10px" }}></Box>
                <img src="/assets/icons/facebook.svg" alt="" />
                <Box sx={{ pl: "10px" }}></Box>
                <img src="/assets/icons/linkedin.svg" alt="" />
                <Box sx={{ pl: "10px" }}></Box>
                <img src="/assets/icons/twitter.svg" alt="" />
              </Box>

              <Box sx={{ ...innerFlexBoxStyle }}>
                <Box sx={{ ...innerFlexBoxStyle }}>
                  <Typography sx={appBarTextStyle}>
                    info@aladwanilawfirm.com
                  </Typography>

                  <Box sx={{ pl: "5px" }}></Box>
                  <img src="/assets/icons/mail.svg" alt="" />
                </Box>
                <Box sx={{ pl: "15px" }}></Box>
                <Box sx={{ ...innerFlexBoxStyle }}>
                  <Typography sx={appBarTextStyle}>96522275475</Typography>
                  <Box sx={{ pl: "5px" }}></Box>
                  <img src="/assets/icons/call.svg" alt="" />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Stack>
    </>
  );

  return (
    <>
      <AppBar
        sx={{
          position: "realtive",
          background: `rgba(30, 24, 76, 1)`,
        }}
      >
        <Toolbar
          sx={
            {
              //height: 1,
              //  px: { lg: 5 },
            }
          }
        >
          {renderContent}
        </Toolbar>
      </AppBar>

      {!isDesktop && !isSmallScreen && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            // pl: "10px",
            pr: "20px",
          }}
          component="nav"
        >
          <Box sx={secontAppBarStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={contactUsBoxStyle}>
                <Typography sx={contactUsTextStyle}>Consult Us</Typography>
              </Box>

              <Box sx={logoStyle}>
                <img src="/assets/icons/logo.svg" alt="" />
              </Box>
            </Box>

            <img onClick={onOpenNav} alt="" src="/assets/icons/icon.svg" />
          </Box>
        </Box>
      )}
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {
  tab: "0",
};

const slice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
  },
  extraReducers: {},
});

// Reducer
export default slice.reducer;

// Actions
export const { setTab } = slice.actions;

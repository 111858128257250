import { Navigate, useRoutes } from "react-router-dom";

//element
import { HomePage } from "./elements";

import DashboardLayout from "../layouts/dashboard/DashboardLayout";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "home-page",
          element: <HomePage />,
        },

        {
          path: "/",
          element: <Navigate to="home-page" replace />,
        },
      ],
    },
  ]);
}

import { Box, Typography, Grid } from "@mui/material";

import useResponsive from "../../hooks/useResponsive";

//_________________________________________________________

//* ------- Styles --------

const leftGridStyle = {
  display: "flex",
  alignItems: "flex-end",
  flexDirection: "column",
  mb: { xs: "20px", sm: "10px", lg: "20px" },
  pr: { lg: "100px", xs: "45px", md: "100px", sm: "40px" },
  mt: { lg: "30px", sm: "0px" },
};

const titleStyle = {
  fontSize: { lg: "32px", sm: "24px", xs: "24px", md: "32px" },
  fontWeight: 700,
  color: "rgba(30, 24, 76, 1)",
  textAlign: "end",
  mb: "5px",
  mt: { lg: "20px" },
  //pr: { lg: "0px", xs: "40px", md: "0px", sm: "0px" },
};

const blackTextStyle = {
  fontSize: { lg: "20px", xs: "16px", md: "20px", sm: "16px" },
  fontWeight: 600,
  color: "#2A2555",
  textAlign: "end",
  // mt: "20px",
};

const blueTextStyle = {
  fontSize: { lg: "32px", xs: "20px", md: "32px", sm: "20px" },
  fontWeight: 700,
  color: "#61CCF5",
  textAlign: "end",
  mt: { lg: "10px", xs: "50px", md: "10px", sm: "10px" },
  mb: { xs: "10px" },
};

const imgStyle = {
  width: { lg: "492px", sm: "282px", xs: "327px", md: "492px" },
  height: { lg: "444px", sm: "331px", xs: "277px", md: "444px" },
  margin: "auto",
};

const lineStyle = {
  width: "125px",
  zIndex: 10,
  display: "flex",
  justifyContent: "flex-end",
};

//___________________________________________________________
export default function RightImageGrid() {
  const isDesktop = useResponsive("up", "lg");

  const isSmallScreen = useResponsive("up", "sm");

  const isMediumScreen = useResponsive("between", "md", "lg");

  //________________________________________

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {!isDesktop && !isMediumScreen && !isSmallScreen && (
            <Box sx={leftGridStyle}>
              <Typography sx={titleStyle}>Our Story</Typography>
              <Box sx={lineStyle}>
                <img alt="" src="/assets/icons/Line 1.svg" />
              </Box>
            </Box>
          )}
          <Box sx={imgStyle}>
            <img alt="" src="/assets/images/عياد العدواني 1.png" />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={leftGridStyle}>
            {(isDesktop || isMediumScreen || isSmallScreen) && (
              <>
                <Typography sx={titleStyle}>Our Story</Typography>
                <Box sx={lineStyle}>
                  <img alt="" src="/assets/icons/Line 1.svg" />
                </Box>
              </>
            )}

            <Typography sx={blueTextStyle}>A Legacy of Excellence</Typography>

            <Typography sx={blackTextStyle}>
              Welcome to Al Adwani Law Firm, a premier Law firm dedicated to
              delivering unparalleled legal services to our esteemed clients.
              Established in 1991 by Mr. Ayyad Al Adwani, our firm has built a
              distinguished reputation through innovative methodologies and
              unwavering dedication to client satisfaction.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

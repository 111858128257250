import { Box, Typography } from "@mui/material";

//_________________________________________________________

//* ------- Styles --------

const firstBoxStyle = {
  backgroundImage: "url('/assets/images/back.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  //flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  height: "667px",
  width: "100%",
  position: "relative", // Ensure the box is positioned relative
  pb: { xs: "20px" },
  pt: { xs: "20px" },
  pr: "80px",
  mb: "50px",
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(30, 24, 76, 0.5)",
  zIndex: 1,
};

const bigWhiteTextStyle = {
  fontSize: { lg: "32px", xs: "32px" },
  fontWeight: { lg: 700, xs: 700 },
  color: "#FFFFFF",
  mb: "15px",
  zIndex: 10,
  textAlign: "center",
};

//___________________________________________________________
export default function NewsInsightstab() {
  //________________________________________

  <>
    {/** first sections  */}
    <Box sx={firstBoxStyle}>
      <Box sx={overlayStyle}></Box>

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
        <Typography sx={bigWhiteTextStyle}>Key Practice Areas</Typography>
        <Box
          sx={{
            width: "66px",
            zIndex: 10,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <img alt="" src="/assets/icons/Line 1.svg" />
        </Box>
      </Box>
    </Box>
  </>;
}

import React, { useState, useRef } from "react";

import { Box, Typography, Button, Grid, Rating, Stack } from "@mui/material";

import Carousel from "react-elastic-carousel";

import useResponsive from "../../hooks/useResponsive";
//_________________________________________________________

//* ------- Styles --------

const secondImgBoxStyle = {
  backgroundImage: "url('/assets/images/second-bg.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "603px",
  width: "100%",
  position: "relative", // Ensure the box is positioned relative
  pb: { xs: "20px" },
  pt: { xs: "20px" },
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(30, 24, 76, 0.5)",
  zIndex: 1,
};

const secondoverlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(30, 24, 76, 0.9)",
  zIndex: 1,
};

const thirdoverlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(245, 245, 245, 0.4)",
  zIndex: 1,
};

const bigWhiteTextStyle = {
  fontSize: { lg: "40px", xs: "24px" },
  fontWeight: { lg: 800, xs: 800 },
  color: "rgba(255, 255, 255, 1)",
  mb: "15px",
  zIndex: 10,
  textAlign: "center",
};

const bigSecondWhiteTextStyle = {
  fontSize: { lg: "32px", xs: "24px", md: "32px", sm: "24px" },
  fontWeight: 700,
  color: "rgba(255, 255, 255, 1)",
  mb: "15px",
  zIndex: 10,
  textAlign: "center",
};

const bigSecondBlueTextStyle = {
  fontSize: { lg: "32px", xs: "20px", md: "32px", sm: "20px" },
  fontWeight: { lg: 700, xs: 700 },
  color: "rgba(97, 204, 245, 1)",
  mb: "15px",
  zIndex: 10,
  textAlign: "center",
};

const smallSecondBlueTextStyle = {
  fontSize: { lg: "20px", xs: "16px", sm: "16px" },
  fontWeight: 500,
  color: "rgba(255, 255, 255, 1)",
  mb: "30px",
  zIndex: 10,
  textAlign: "center",
};

const contentStyle = {
  fontSize: "16px",
  fontWeight: 500,
  color: "rgba(251, 251, 251, 1)",
  zIndex: 10,
  textAlign: "center",
};

const buttonAiStyle = {
  color: "#1E184C",
  fontWeight: 400,
  fontSize: { xl: "12px", lg: "12px", xs: "12px" },
  bgcolor: "rgba(97, 204, 245, 1)",
  height: { xl: "40px", lg: "40px", xs: "40px" },
  width: { xl: "142px", lg: "142px", xs: "142px" },
  borderRadius: "0px",
  zIndex: 10,
  mt: "20px",
  mb: { xs: "20px", sm: "0px" },
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const buttonDateStyle = {
  color: "#FFFFFF",
  fontWeight: 700,
  fontSize: { xl: "12px", lg: "12px", xs: "12px" },
  bgcolor: "#1E184C",
  height: { xl: "34px", lg: "34px", xs: "34px" },
  width: { xl: "156px", lg: "156px", xs: "156px" },
  borderRadius: "0px",
  zIndex: 10,
  mt: "20px",
  mb: { xs: "20px", sm: "0px" },
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const flexBoxStyle = {
  display: "flex",
  justifyContent: { lg: "flex-end", sm: "flex-end", xs: "center" },
  alignItems: "center",
  width: "100%",
};

const titleStyle = {
  fontSize: { lg: "32px", sm: "24px", xs: "24px", md: "32px" },
  fontWeight: 700,
  color: "rgba(30, 24, 76, 1)",
  textAlign: "center",
  mb: "5px",
  mt: "20px",
};

const textStyle = {
  fontSize: { lg: "20px", sm: "16px", xs: "16px", md: "20px" },
  fontWeight: 500,
  color: "rgba(30, 24, 76, 1)",
};

const boxTitleStyle = {
  display: "flex",
  alignItems: "Center",
  flexDirection: "column",
  mb: { xs: "20px", sm: "10px", lg: "20px" },
};

const gridStyle = {
  pl: "30px",
  pr: "30px",
};

const secondGrid = {
  display: "flex",
  flexDirection: "column",
  background: "rgba(245, 245, 245, 1)",
  mt: "20px",
  pl: "30px",
  pr: "30px",
};

const blueTitle = {
  fontSize: { lg: "20px", md: "20px", sm: "16px", xs: "16px" },
  fontWeight: 700,
  color: "rgba(97, 204, 245, 1)",
  mb: "10px",
  mt: { lg: "20px", md: "20px", sm: "10px", xs: "20px" },
  textAlign: "center",
};

const blackTitle = {
  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
  fontWeight: 500,
  color: "rgba(0, 0, 0, 1)",
};

const whTextStyle = {
  fontSize: { lg: "20px", xs: "16px", md: "20px", sm: "16px" },
  fontWeight: 700,
  color: "rgba(255, 255, 255, 1)",
  mb: "15px",
  zIndex: 10,
  textAlign: "end",
};

const smallBlue = {
  fontSize: { lg: "18px", xs: "12px", md: "18px", sm: "12px" },
  fontWeight: 400,
  color: "rgba(97, 204, 245, 1)",
};

const nameStyle = {
  fontSize: { lg: "24px", xs: "24px", md: "24px", sm: "24px" },
  fontWeight: 600,
  color: "#2A2555",
  textAlign: "center",
  mt: "20px",
};

const jobStyle = {
  fontSize: { lg: "20px", xs: "20px", md: "20px", sm: "20px" },
  fontWeight: 600,
  color: "#61CCF5",
  textAlign: "center",
};

const personStyle = {
  width: { lg: "449px", sm: "277px", xs: "229px", md: "449px" },
  height: { lg: "423px", sm: "314px", xs: "221px", md: "423px" },
  margin: "auto",
};

const cardImgStyle = {
  width: { lg: "352px", sm: "170px", xs: "100", md: "352px" },
  height: { lg: "273px", sm: "130px", xs: "auto", md: "273px" },
};

const boxCardImgStyle = {
  width: { lg: "352px", sm: "170px", xs: "100%", md: "352px" },
  height: { lg: "140px", sm: "170px", xs: "auto", md: "273px" },
  display: "flex",
  flexDirection: "column",
  alignItems: "Center",
  background: "rgba(255, 255, 255, 1)",
  pl: "20px",
  pr: "20px",
  pb: { xs: "20px" },
  mb: { xs: "20px" },
};

const ourPeopleImg = {
  width: { lg: "343px", sm: "270px", xs: "100", md: "392px" },
  height: { lg: "370px", sm: "300px", xs: "auto", md: "379px" },
  margin: "auto",
};

const formBoxStyle = {
  backgroundImage: "url('/assets/images/ph.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "529px",
  width: "100%",
  position: "relative", // Ensure the box is positioned relative
  pb: { xs: "20px" },
  pt: { xs: "20px" },
};

const overlayFormStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "#1E184CF0",
  zIndex: 1,
};

const data = [
  {
    id: 1,
    title: "Agency & Distributorship",
    description:
      "At Al Adwani Law Firm, we have extensive expertise in navigating the complex legal frameworks governing agency.",
    imageUrl: "/assets/images/Mask group.png",
  },

  {
    id: 2,
    title: "Aviation Law",
    description:
      "At Al Adwani Law Firm, we offer specialized legal expertise in the dynamic and highly regulated aviation industry.",
    imageUrl: "/assets/images/Mask group2.png",
  },

  {
    id: 3,
    title: "Banking& Finance",
    description:
      "At Al Adwani Law Firm, we provide comprehensive legal services in the field of banking and finance,",
    imageUrl: "/assets/images/Mask group3.png",
  },
];

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1, itemsToScroll: 1 },
  { width: 768, itemsToShow: 1 },
  { width: 1200, itemsToShow: 1 },
];

var items = [
  { id: 1, image: "/assets/images/back.png" },
  { id: 2, image: "/assets/images/second-bg.png" },
  //  { id: 3, image: "/assets/images/Mask group2.png" },
];

var itemBox = [{ id: 1 }, { id: 2 }, { id: 3 }];
//___________________________________________________________

export default function HomeTab() {
  const arrow = ({ onClick }) => {
    return (
      <Button sx={{ display: "none" }} onClick={onClick} disabled={true} />
    );
  };

  const isDesktop = useResponsive("up", "lg");

  const isSmallScreen = useResponsive("up", "sm");

  const isMediumScreen = useResponsive("between", "md", "lg");

  const [activeIndex, setActiveIndex] = useState(0);

  const carouselRef = useRef(null);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  //________________________________________

  return (
    <>
      {/** first sections  */}

      <Carousel
        // ref={carouselRef}
        breakPoints={breakPoints}
        renderArrow={arrow}
        style={{ margin: "0", padding: "0", width: "100%" }}
        enableAutoPlay={true}
        infinite={true}
        autoPlaySpeed={7000}
        disableArrowsOnEnd={true}
        autoTabIndexVisibleItems={true}
        className="carousel-custom"
        onChange={(currentItem) => setActiveIndex(currentItem.index)}
        renderDots={() => null}
      >
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              background: `url(${item.image}) no-repeat center center fixed`,
              width: "100%",
              position: "relative",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "667px",
              pb: { xs: "20px" },
              pt: { xs: "20px" },
            }}
          >
            <Box sx={overlayStyle}></Box>

            <Box
              sx={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                width: { md: "30%", xs: "60%", sm: "55%" },
              }}
            >
              <Typography sx={bigWhiteTextStyle}>
                Over{" "}
                <span style={{ color: "rgba(97, 204, 245, 1)" }}>
                  Three Decades
                </span>{" "}
                of Legal Expertise
              </Typography>

              <Typography sx={contentStyle}>
                Offering tailored legal services in Kuwait and internationally,
                specializing in corporate law, litigation, banking, and more
              </Typography>

              <Box sx={{ ...flexBoxStyle, justifyContent: "flex-start" }}>
                <Button
                  fullWidth
                  disableRipple
                  size="large"
                  type="submit"
                  variant="Contained"
                  sx={buttonAiStyle}
                >
                  Explore More
                </Button>
              </Box>
            </Box>
            {/** 
            <Box className="rec-dot">
              {" "}
              {items.map((_, dotIndex) => (
                <span
                  key={dotIndex}
                  onClick={() => handleDotClick(dotIndex)} // Handle click to change slide
                />
              ))}
            </Box>
            */}
          </Box>
        ))}
      </Carousel>

      {/** second sections  */}
      {/** about us */}
      <Box sx={boxTitleStyle}>
        <Typography sx={titleStyle}>About Us</Typography>
        <img alt="" src="/assets/icons/Line 1.svg" />
      </Box>

      <Grid container columnSpacing={2} sx={gridStyle}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              pl: { lg: "70px", xs: "10px", sm: "10px" },
            }}
          >
            <Typography sx={{ ...textStyle, textAlign: "end" }}>
              Al Adwani Law Firm was founded by Mr. Al Adwani, providing legal
              services with a client-focused approach. Our team of experienced
              lawyers is committed to delivering top-notch legal advice to a
              wide range of industries. With decades of success, we’ve built
              strong relationships in both local and international markets
            </Typography>

            <Box sx={flexBoxStyle}>
              <Button
                fullWidth
                disableRipple
                size="large"
                type="submit"
                variant="Contained"
                sx={buttonAiStyle}
              >
                Explore More
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={personStyle}>
            <img alt="" src="/assets/images/عياد العدواني 1.png" />
          </Box>
        </Grid>
      </Grid>

      {/** third sections  */}
      <Box sx={secondGrid}>
        <Box sx={boxTitleStyle}>
          <Typography sx={titleStyle}>Key Practice Areas</Typography>
          <img alt="" src="/assets/icons/Line 1.svg" />
        </Box>

        <Box sx={{ pl: { lg: "25%" }, pr: { lg: "25%" } }}>
          <Typography sx={{ ...textStyle, textAlign: "end" }}>
            Al Adwani Law Firm offers comprehensive legal services in corporate
            law, litigation, arbitration, IP, and more, delivering tailored
            solutions to meet your goals and ambitions
          </Typography>
        </Box>

        <Grid container columnSpacing={1} mb="20px" mt="20px">
          {/**<Carousel
            breakPoints={breakPoints}
            // renderArrow={arrow}
            style={{ margin: "0", padding: "0", width: "100%" }}
            enableAutoPlay={true}
            infinite={true}
            autoPlaySpeed={7000}
            disableArrowsOnEnd={true}
            autoTabIndexVisibleItems={true}
            className="carousel-custom"
            onChange={(currentItem) => setActiveIndex(currentItem.index)}
            //  renderDots={() => null}
            itemsToShow={2}
          > */}

          {data.map((item) => (
            <Grid item xs={12} sm={4} lg={4} key={item.id}>
              <Box sx={cardImgStyle}>
                <img src={item.imageUrl} alt="" width={"100%"} />
              </Box>

              <Box sx={boxCardImgStyle}>
                <Typography sx={blueTitle}>{item.title}</Typography>

                <Typography
                  sx={{
                    ...blackTitle,
                    textAlign: { lg: "end", sm: "end", xs: "center" },
                  }}
                >
                  {" "}
                  {item.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Button
          fullWidth
          disableRipple
          size="large"
          type="submit"
          variant="Contained"
          sx={{ ...buttonAiStyle, margin: "auto" }}
        >
          Explore More
        </Button>

        <Box sx={{ pb: "20px" }}></Box>
      </Box>

      {/** forth sections  */}
      <Box sx={secondImgBoxStyle}>
        <Box sx={secondoverlayStyle}></Box>
        <Box sx={boxTitleStyle}>
          <Typography sx={bigSecondWhiteTextStyle}>Testimonials</Typography>
          <img alt="" src="/assets/icons/Line 1.svg" style={{ zIndex: 10 }} />
        </Box>

        <Typography sx={bigSecondBlueTextStyle}>
          Discover success stories from satisfied clients
        </Typography>
        <Box sx={{ pr: { lg: "30%" }, pl: { lg: "30%" }, zIndex: 10 }}>
          <Typography sx={{ ...smallSecondBlueTextStyle, textAlign: "center" }}>
            See how our personalized legal solutions have made a difference for
            clients across diverse industries and cases
          </Typography>
        </Box>
        <Carousel
          breakPoints={breakPoints}
          // renderArrow={arrow}
          style={{ margin: "0", padding: "0", width: "100%" }}
          enableAutoPlay={true}
          infinite={true}
          autoPlaySpeed={7000}
          disableArrowsOnEnd={true}
          autoTabIndexVisibleItems={true}
          className="carousel-custom"
          onChange={(currentItem) => setActiveIndex(currentItem.index)}
          //  renderDots={() => null}
          itemsToShow={2}
        >
          {itemBox.map((item) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid rgba(97, 204, 245, 1)",
                position: "relative",
                height: { lg: "282px", sm: "282px", xs: "330px" },
                width: { lg: "656px", xs: "90%" },
                p: "20px",
                ml: "30px",
                mr: "30px",
                pl: "30px",
                pr: "30px",
              }}
            >
              <Box sx={thirdoverlayStyle}></Box>

              <Box
                sx={{
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  // alignItems: "center",
                }}
              >
                <img
                  alt=""
                  src="/assets/icons/dot.svg"
                  style={{ width: "15px", height: "30px" }}
                />
              </Box>

              <Typography sx={whTextStyle}>
                When our bank faced regulatory challenges, Al Adwani stepped in
                with their deep expertise. I can say they navigated the
                complexities of the law with ease, helping us resolve the issues
                quickly.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  zIndex: 1,
                }}
              >
                <Box sx={{ width: "21px", height: "30px", zIndex: 1 }}>
                  <img alt="" src="/assets/icons/dot.svg" />
                </Box>
                <Typography sx={smallBlue}>
                  Sarah Thompson, Gulf Trust Bank
                </Typography>
              </Box>

              <Stack spacing={1} zIndex={10} margin={"auto"}>
                <Rating name="half-rating" defaultValue={2.5} precision={0.5} />
              </Stack>
            </Box>
          ))}
        </Carousel>
      </Box>

      {/** fifth sections  */}
      <Box sx={{ ...secondGrid, mt: "0px", backgroundColor: "#FFFFFF" }}>
        <Box sx={boxTitleStyle}>
          <Typography sx={titleStyle}>Blog</Typography>
          <img alt="" src="/assets/icons/Line 1.svg" />
        </Box>

        <Grid container columnSpacing={1} mt="20px" pb="20px">
          {data.map((item) => (
            <Grid item xs={12} sm={4} lg={4} key={item.id}>
              <Box sx={cardImgStyle}>
                <img src={item.imageUrl} alt="" width={"100%"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  pl: "10px",
                  pr: "10px",
                  background: "rgba(255, 255, 255, 1)",
                  // height: { lg: "166px", xs: "130px" },
                  mb: { xs: "30px", sm: "0px" },
                  boxShadow:
                    "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
                  position: "relative",
                  width: { lg: "352px", sm: "170px", xs: "100%", md: "352px" },
                  height: { lg: "140px", sm: "170px", xs: "auto", md: "273px" },
                  pb: { xs: "66px" },
                }}
              >
                <Typography
                  sx={{ ...blackTitle, textAlign: "end", mt: "30px" }}
                >
                  {" "}
                  {item.description}
                </Typography>

                <Box
                  sx={{
                    position: "absolute",
                    bottom: { lg: -20, xs: -40, sm: -20 },
                  }}
                >
                  <Button
                    fullWidth
                    disableRipple
                    size="large"
                    type="submit"
                    variant="Contained"
                    sx={{ ...buttonAiStyle, margin: "auto" }}
                  >
                    Learn More
                  </Button>
                </Box>

                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    bottom: { lg: 125, xs: 97, sm: 155, md: 125 },
                  }}
                >
                  <Button
                    fullWidth
                    disableRipple
                    size="large"
                    type="submit"
                    variant="Contained"
                    sx={{ ...buttonDateStyle, margin: "auto" }}
                  >
                    24th March 2024
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ pb: "20px" }}></Box>
      </Box>

      {/** 6 sections  */}
      <Box sx={{ ...secondGrid, mt: "0px", pb: "50px" }}>
        <Box sx={boxTitleStyle}>
          <Typography sx={titleStyle}>Our People</Typography>
          <img alt="" src="/assets/icons/Line 1.svg" />
        </Box>

        <Grid container columnSpacing={2} mt="20px" pb="20px">
          <Grid item xs={12} sm={6} lg={4} mb={{ xs: "30px" }}>
            <Box sx={ourPeopleImg}>
              <img alt="" src="/assets/images/عصام العدواني 1.png" />
            </Box>
            <Typography sx={nameStyle}>Esam Aladwani</Typography>

            <Typography sx={jobStyle}>Partner</Typography>
          </Grid>

          <Grid item xs={12} sm={6} lg={4} mb={{ xs: "30px" }}>
            <Box sx={ourPeopleImg}>
              <img alt="" src="/assets/images/name.png" />
            </Box>
            <Typography sx={nameStyle}>Husam Aladwani</Typography>

            <Typography sx={jobStyle}>Managing Partner</Typography>
          </Grid>

          {isDesktop && (
            <Grid item xs={12} sm={6} lg={4} mb={{ xs: "30px" }}>
              <Box sx={ourPeopleImg}>
                <img alt="" src="/assets/images/Rectangle 4502.png" />
              </Box>
              <Typography sx={nameStyle}>Ayyad Aladwani</Typography>

              <Typography sx={jobStyle}>Chairman</Typography>
            </Grid>
          )}
        </Grid>

        <Button
          fullWidth
          disableRipple
          size="large"
          type="submit"
          variant="Contained"
          sx={{ ...buttonAiStyle, margin: "auto" }}
        >
          Explore More
        </Button>
      </Box>

      {/** 7 sections  */}
      <Box sx={formBoxStyle}>
        <Box sx={overlayFormStyle}></Box>
        <Grid container columnSpacing={2}></Grid>
      </Box>

      {/** 8 sections  */}
      <Box sx={{ ...secondGrid, mt: "0px", backgroundColor: "#FBFBFB" }}>
        <Box sx={boxTitleStyle}>
          <Typography sx={titleStyle}>Our Happy Clients</Typography>
          <img alt="" src="/assets/icons/Line 1.svg" />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: { lg: "85%", sm: "100%" },
            margin: "auto",
            pb: "20px",
          }}
        >
          <img alt="" src="/assets/images/Asset 29.png" />

          <img alt="" src="/assets/images/Asset 28.png" />

          <img alt="" src="/assets/images/Asset 27.png" />

          <img alt="" src="/assets/images/Asset 26.png" />

          <img alt="" src="/assets/images/Asset 25.png" />
        </Box>
      </Box>
    </>
  );
}

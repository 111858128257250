import { Box, Typography, Grid } from "@mui/material";

//_________________________________________________________

//* ------- Styles --------

const firstBoxStyle = {
  backgroundImage: "url('/assets/images/back.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  //flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  height: "667px",
  width: "100%",
  position: "relative", // Ensure the box is positioned relative
  pb: { xs: "20px" },
  pt: { xs: "20px" },
  pr: "80px",
  mb: "50px",
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(30, 24, 76, 0.5)",
  zIndex: 1,
};

const bigWhiteTextStyle = {
  fontSize: { lg: "32px", xs: "32px" },
  fontWeight: { lg: 700, xs: 700 },
  color: "#FFFFFF",
  mb: "15px",
  zIndex: 10,
  textAlign: "center",
};

const cardImgStyle = {
  width: { lg: "352px", sm: "170px", xs: "100px", md: "352px" },
  height: { lg: "273px", sm: "130px", xs: "auto", md: "273px" },
};

const boxCardImgStyle = {
  width: { lg: "352px", sm: "170px", xs: "100%", md: "352px" },
  height: { lg: "140px", sm: "170px", xs: "auto", md: "273px" },
  display: "flex",
  flexDirection: "column",
  alignItems: "Center",
  background: "rgba(255, 255, 255, 1)",
  pl: "20px",
  pr: "20px",
  pb: { xs: "20px" },
  mb: { xs: "20px" },
};

const ourPeopleImg = {
  width: { lg: "343px", sm: "270px", xs: "100", md: "392px" },
  height: { lg: "370px", sm: "300px", xs: "auto", md: "379px" },
  margin: "auto",
};

const nameStyle = {
  fontSize: { lg: "24px", xs: "24px", md: "24px", sm: "24px" },
  fontWeight: 600,
  color: "#2A2555",
  textAlign: "center",
  mt: "20px",
};

const jobStyle = {
  fontSize: { lg: "20px", xs: "20px", md: "20px", sm: "20px" },
  fontWeight: 600,
  color: "#61CCF5",
  textAlign: "center",
};
//___________________________________________________________
export default function PracticeAreasTab() {
  //________________________________________

  return (
    <>
      {/** first sections  */}
      <Box sx={firstBoxStyle}>
        <Box sx={overlayStyle}></Box>

        <Box
          sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
        >
          <Typography sx={bigWhiteTextStyle}>Key Practice Areas</Typography>
          <Box
            sx={{
              width: "66px",
              zIndex: 10,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img alt="" src="/assets/icons/Line 1.svg" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
